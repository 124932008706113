import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Constants from './../utilities/Constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';

// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import ReactCaptcha from 'modern-react-captcha';
import './index.css';


const Search = () => {

    const [loading, setLoading]                           = React.useState(false);
    const [selectedConstituency, setSelectedConstituency] = React.useState(false);
    const [firstName, setFirstName]                       = React.useState('');
    const [middleName, setMiddleName]                     = React.useState('');
    const [lastName, setLastName]                         = React.useState('');
    const [epicNo, setEpicNo]                             = React.useState('');
    const [open, setOpen]                                 = React.useState(false);
    const [isCaptcha, setIsCaptcha]                       = React.useState(true);
    const [openCaptcha, setOpenCaptcha]                   = React.useState(false);
    const [schools, setSchools]                           = React.useState([]);
    const [school, setSchool]                             = React.useState('');
    const [form, setForm]                                 = React.useState({})
    const [element, setElement]                           = React.useState([])

    // const handleSuccess = () => setIsCaptcha(true);
	// const handleFailure = () => setIsCaptcha(false);

    const setElementValue = (ele, v) => {
        if(v.length < 3) {
            return
        }
        let f = form
        f[ele.col] = v
        // console.log(objectToQuerystring(f))
        setForm(f)
    }

    function objectToQuerystring (obj) {
        return Object.keys(obj).reduce(function (str, key, i) {
          var delimiter, val;
          delimiter = (i === 0) ? '?' : '&';
          key = encodeURIComponent(key);
          val = encodeURIComponent(obj[key]);
          return [str, delimiter, key, '=', val].join('');
        }, '');
      }

    const setFirstNameC = name => {
        setFirstName(name)
        Constants.search.firstName = name
    }

    const setMiddleNameC = name => {
        setMiddleName(name)
        Constants.search.middleName = name
    }

    const setLastNameC = name => {
        setLastName(name)
        Constants.search.lastName= name
    }

    const setEpicNoC = number => {
        setEpicNo(number)
        Constants.search.epicNo = number
    }

    const setSchoolC = schoolname => {
        setSchool(schoolname.label)
        Constants.search.school = schoolname.label
    }

    const valid = () => {
        let search = objectToQuerystring(form)
        return search ? true : false
    }
 
    const searchvoter = () => {
        if(valid()) {
            if(isCaptcha) {
                Constants.voters = []
                Constants.search.string = objectToQuerystring(form)
                setTimeout(() => Constants.navigate('/list'), 50)
            } else {
                setOpen(false)
                setOpenCaptcha(true)
                setLoading(false)
            }
        } else {
            setLoading(false)
            setOpen(true)
            setOpenCaptcha(false)
        }
        
    }

    React.useEffect(() => {
        if(Constants?.selectedConstituency === undefined) {
            Constants.navigate('/')
        }
        setSelectedConstituency(Constants?.selectedConstituency)
        Constants.search = {};
        load()
    }, [])

    const load = () => {
        setElement(Constants.lables.filter(l => l.constituency_id == Constants?.selectedConstituency?.constituency_id)
                                   .filter(l => l.language_code == Constants.selectedLanguage)
                                   .filter(l => l.is_search === 1))
    }
    let is_or = true;

    return (
        <>
            <Card >
            
                <CardHeader title={`${Constants.selectedLanguage === 'en' ? selectedConstituency?.constituencies?.name : selectedConstituency?.constituencies?.name}`} sx={{ textAlign: 'center' }} className='bg-brand' />

                <CardContent>
                    {
                        // console.log(element, 'element')
                        element.map(ele => 
                        
                            <>
                                {ele.col == "cardno" ? <p style={{display: 'flex', justifyContent: 'center'}}>OR</p> : ''}
                                <TextField
                                    sx={{mb: 2}}
                                    label={ele.label}
                                    id="outlined-size-small-f-name"
                                    size="small"
                                    fullWidth
                                    onChange={e => setElementValue(ele, e.target.value)}
                                />
                            </>
                            
                        
                        )
                    }
                </CardContent>


                <CardActions>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                Constants.navigate('/')
                            }
                        }
                    >
                        <span> {'<'} {Constants?.languages[Constants.selectedLanguage]?.search?.btn?.back}</span>
                    </LoadingButton>

                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                searchvoter()
                            }
                        }
                    >
                        <span>{Constants.languages[Constants.selectedLanguage].search.btn.submit}</span>
                    </LoadingButton>
                </CardActions>
            </Card>
            <Snackbar
                open={open}
            >
                <Alert severity="error">Please check search parameters, parameters should be atleas 3 charactors.</Alert>
            </Snackbar>
        </>
            
    )
}

export default Search;
